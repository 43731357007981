import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonProgressBar,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import dayjs from 'dayjs';
import { assertIsDefined, callPhoneNumber, PARSED_ENTRY_MATCH_TTL } from '../utils';
import {
  EntryMatchParsedDetailsEntryMarkViewedMutationGql,
  EntryMatchParsedDetailsSearchParserEntryMatchByIdQueryGql,
} from './entry-match-parsed-details.gql-gen';

type EntryMatchParsedUIData = {
  foundAgo: string;
  machineTypeName: string;
  message: string;
  fullName: string;
  phoneNumber: string;
};

@Component({
  selector: 'app-entry-match-parsed-details',
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonChip,
    IonProgressBar,
    IonContent,
    IonText,
    IonItem,
    IonAvatar,
    IonIcon,
    IonButton,
    IonSpinner,
  ],
  templateUrl: './entry-match-parsed-details.component.html',
  styleUrl: './entry-match-parsed-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryMatchParsedDetailsComponent implements OnInit, OnDestroy {
  private sub?: Subscription;
  private markViewedSub?: Subscription;
  protected pending = false;
  protected entryMatch?: EntryMatchParsedUIData;
  protected foundProgress = 0;

  constructor(
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private searchParserEntryMatchByIdQueryGql: EntryMatchParsedDetailsSearchParserEntryMatchByIdQueryGql,
    private entryMarkViewedMutationGql: EntryMatchParsedDetailsEntryMarkViewedMutationGql,
  ) {}

  ngOnInit() {
    this.pending = true;
    this.foundProgress = 0;
    this.sub = this.activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          const entryMatchIds = [params['entryId']];
          return this.searchParserEntryMatchByIdQueryGql.fetch({ entryMatchIds });
        }),
      )
      .subscribe({
        next: (res) => {
          assertIsDefined(res.data.searchParserEntryMatchById);
          assertIsDefined(res.data.authCurrentUser);

          const match = res.data.searchParserEntryMatchById[0];
          assertIsDefined(match);

          const machineTypeName = match.request.machineTypeName;
          const message = match.comment ?? '';
          const foundAgo = dayjs(match.found).fromNow();
          const foundProgress =
            (dayjs().unix() - dayjs(match.found).unix()) / PARSED_ENTRY_MATCH_TTL;
          const fullName = match.contactName;
          const phoneNumber = match.contactPhone ?? '';

          this.entryMatch = {
            machineTypeName,
            message,
            foundAgo,
            fullName,
            phoneNumber,
          };
          this.pending = false;
          this.foundProgress = foundProgress;
          this.cdRef.markForCheck();

          this.markViewedSub = this.entryMarkViewedMutationGql
            .mutate({
              entryId: match.id,
              uuidUser: res.data.authCurrentUser.uuid,
            })
            .subscribe({
              next: (_res) => {},
              error: () => {},
            });
        },
        error: () => {
          // todo: error handling
        },
      });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.markViewedSub?.unsubscribe();
  }

  onCallButtonClick(ev: Event) {
    ev.preventDefault();
    assertIsDefined(this.entryMatch);
    callPhoneNumber(this.entryMatch.phoneNumber);
  }
}
