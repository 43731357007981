@if (userFirstName !== undefined) {
  <ion-header>&nbsp;</ion-header>
  <ion-content>
    <p class="ion-no-margin">
      @if (userFirstName) {
        <span>{{ userFirstName }}</span>
        <span>,&nbsp;</span>
        <span>
          рады видеть вас в обновленном Рентал Клаб - сервисе для быстрой и безопасной аренды
          спецтехники.
        </span>
      } @else {
        <span>
          Рады видеть вас в обновленном Рентал Клаб - сервисе для быстрой и безопасной аренды
          спецтехники.
        </span>
      }
    </p>
    <p>
      Сервис доступен как в мобильном приложении, так и в
      <a href="https://lk.rental-club.ru/auth/login" target="_blank">личном кабинете</a>
      <span>.</span>
    </p>

    <h3>Если вы заказчик:</h3>

    <ul class="ion-no-margin">
      <li>Создавайте заявки на аренду техники у проверенных поставщиков.</li>
      <li>Подписывайте заявки с поставщиком для фиксации цены и условий работы.</li>
      <li>Оценивайте работу техники по факту выполнения заказа.</li>
      <li>
        Ведите учет и контроль за работой техники используя
        <a href="https://lk.rental-club.ru/auth/login" target="_blank">личный кабинет</a>
        <span>.</span>
      </li>
    </ul>
    <h3>Если вы поставщик:</h3>
    <ul class="ion-no-margin">
      <li>
        Получайте заявки именно на вашу технику от диспетчерских компаний, конечных заказчиков, а
        также заявки из внешних источников.
      </li>
      <li>
        Получайте гарантированную оплату на определенный тип заказов от партнеров Рентал Клаб.
      </li>
      <li>
        Ведите учет и документооборот с вашим заказчиком используя
        <a href="https://lk.rental-club.ru/auth/login" target="_blank">личный кабинет</a>
        <span>.</span>
      </li>
    </ul>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-button
      (click)="onContinueButtonClick($event)"
      expand="block"
      class="ion-padding"
      size="large"
      color="rcyellow"
    >
      <b>Всё понятно!</b>
    </ion-button>
  </ion-footer>
}
