<ion-header>
  <ion-toolbar>
    <ion-title color="dark" class="ion-padding-top">Сдать технику</ion-title>
    <ion-segment [value]="selectedSegment" (ionChange)="onSegmentChange($event)">
      <ion-segment-button value="activeMatches">
        <ion-label>Заявки</ion-label>
      </ion-segment-button>
      <ion-segment-button value="fakeOrders">
        <ion-label>Заказы</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
@if (currentView === "activeMatches") {
  <ion-content>
    <ion-refresher
      [disabled]="activeMatchesState.pending"
      (ionRefresh)="onActiveMatchesRefresh($event)"
      slot="fixed"
    >
      <ion-refresher-content
        pullingIcon="chevron-down-circle-outline"
        pullingText="Потянуть для обновления"
        refreshingSpinner=""
        refreshingText="Обновляем список..."
      ></ion-refresher-content>
    </ion-refresher>
    @if (activeMatchesState.pending && activeMatchesState.items.length === 0) {
      <ion-item lines="full" class="ion-no-padding">
        <ion-toggle class="ion-padding-start" color="primary" [disabled]="true">
          <span>Только на мою технику</span>
        </ion-toggle>
      </ion-item>
      <div class="spinner-wrapper">
        <ion-spinner name="lines-sharp"></ion-spinner>
      </div>
    }
    @if (!activeMatchesState.pending && activeMatchesState.items.length === 0) {
      <div class="no-matches">
        <ion-item lines="full" class="ion-no-padding">
          <ion-toggle
            class="ion-padding-start"
            color="primary"
            [checked]="true"
            (ionChange)="onActiveMatchesToggleChange($event)"
          >
            <span>Только на мою технику</span>
          </ion-toggle>
        </ion-item>
        <div class="no-matches-info">
          <ion-img src="assets/no-matches.svg" alt="there is no matches"></ion-img>
          <span>Пока нет заявок на вашу технику</span>
        </div>
        <ion-button
          class="ion-padding"
          expand="block"
          size="large"
          color="rcyellow"
          [queryParams]="{ segment: 'machines' }"
          [routerLink]="'/home/profile'"
        >
          Добавить технику
        </ion-button>
      </div>
    }
    @if (activeMatchesState.items.length) {
      <ion-item lines="full" class="ion-no-padding">
        <ion-toggle
          class="ion-padding-start"
          color="primary"
          [checked]="true"
          (ionChange)="onActiveMatchesToggleChange($event)"
        >
          <span>Только на мою технику</span>
        </ion-toggle>
      </ion-item>
      <ion-list class="ion-no-padding">
        @for (match of activeMatchesState.items; track match.id) {
          @if (match.organizationName) {
            <ion-item class="custom-item" (click)="onActiveMatchCardClick($event, $index)">
              <ion-avatar slot="start" class="avatar-with-icon">
                @if (match.avatarURL) {
                  <ion-img [src]="match.avatarURL + '?w=128'"></ion-img>
                } @else {
                  <ion-icon name="person-circle"></ion-icon>
                }
              </ion-avatar>
              <div class="match-item-content">
                <ion-label class="custom-label ion-no-padding ion-no-margin">
                  <div class="company-info">
                    <div class="company-name">
                      <ion-text>{{ match.organizationName }}</ion-text>
                      @if (match.isOrganizationVerified) {
                        <ion-icon name="checkmark-circle-outline" color="rcgreen"></ion-icon>
                      }
                      <ion-text class="user-name">&nbsp;{{ match.userFullName }}</ion-text>
                    </div>
                    <ion-text class="company-time">{{ match.foundAgo }}</ion-text>
                  </div>
                  <ion-text class="machine-type">{{ match.machineTypeNameRu }}</ion-text>
                  <p class="details">
                    @if (match.isWDToday) {
                      <ion-text color="rcred">{{ match.workDateTime }}&nbsp;</ion-text>
                    } @else {
                      <ion-text color="dark">{{ match.workDateTime }}&nbsp;</ion-text>
                    }
                    @if (match.workAmountUnit) {
                      <ion-text color="dark">{{ match.workAmountUnit }}&nbsp;</ion-text>
                    }
                    @if (match.distanceToBaseKm) {
                      <ion-text color="dark">
                        <span>&nbsp;·&nbsp;</span>
                        <ion-text color="dark">{{ match.distanceToBaseKm }}</ion-text>
                        <span>&nbsp;км от базы</span>
                      </ion-text>
                    }
                  </p>
                </ion-label>

                @if (match.waitingForSign) {
                  <ion-chip color="rcgreen" slot="end">Подписана заказчиком</ion-chip>
                } @else if (match.isProposed) {
                  <ion-chip color="primary" slot="end">Сделано предложение</ion-chip>
                } @else if (match.viewed) {
                  <ion-chip color="medium" slot="end">Просмотрена</ion-chip>
                }
              </div>
            </ion-item>
          } @else {
            <ion-item class="custom-item" (click)="onActiveMatchCardClick($event, $index)">
              <ion-avatar class="avatar-with-icon" slot="start">
                <ion-icon name="person-circle"></ion-icon>
              </ion-avatar>
              <div>
                <ion-label class="custom-label">
                  <div class="parser-name">
                    <ion-text class="parser-name">{{ match.msgUserName }}</ion-text>
                    <ion-text>{{ match.foundAgo }}</ion-text>
                  </div>

                  <ion-text class="machine-type">{{ match.machineTypeNameRu }}</ion-text>
                  <ion-text class="parser-details">
                    {{ match.msgComment }}
                  </ion-text>
                </ion-label>
                @if (match.waitingForSign) {
                  <ion-chip color="rcgreen" slot="end">Подписана заказчиком</ion-chip>
                } @else if (match.isProposed) {
                  <ion-chip color="primary" slot="end">Сделано предложение</ion-chip>
                } @else if (match.viewed) {
                  <ion-chip color="medium" slot="end">Просмотрена</ion-chip>
                }
              </div>
            </ion-item>
          }
        }
      </ion-list>
      @if (activeMatchesState.hasNext) {
        <ion-infinite-scroll (ionInfinite)="onActiveMatchesInfiniteScrollInfinite($event)">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      }
    }
  </ion-content>
}
@if (currentView === "inactiveMatches") {
  <ion-content>
    <ion-refresher
      [disabled]="inactiveMatchesState.pending"
      (ionRefresh)="onInactiveMatchesRefresh($event)"
      slot="fixed"
    >
      <ion-refresher-content
        pullingIcon="chevron-down-circle-outline"
        pullingText="Потянуть для обновления"
        refreshingSpinner=""
        refreshingText="Обновляем список..."
      ></ion-refresher-content>
    </ion-refresher>
    @if (inactiveMatchesState.pending && inactiveMatchesState.items.length === 0) {
      <ion-item lines="full" class="ion-no-padding">
        <ion-toggle class="ion-padding-start" color="primary" [disabled]="true" [checked]="false">
          <span>Только на мою технику</span>
        </ion-toggle>
      </ion-item>
      <div class="spinner-wrapper">
        <ion-spinner name="lines-sharp"></ion-spinner>
      </div>
    }
    @if (inactiveMatchesState.items.length) {
      <ion-item lines="full" class="ion-no-padding">
        <ion-toggle
          class="ion-padding-start"
          color="primary"
          [checked]="false"
          (ionChange)="onActiveMatchesToggleChange($event)"
        >
          <span>Только на мою технику</span>
        </ion-toggle>
      </ion-item>
      <ion-list class="ion-no-padding">
        @for (match of inactiveMatchesState.items; track match.id) {
          @if (match.organizationName) {
            <ion-item class="custom-item custom-inactive-item">
              <ion-avatar slot="start" class="avatar-with-icon">
                @if (match.avatarURL) {
                  <ion-img [src]="match.avatarURL + '?w=128'"></ion-img>
                } @else {
                  <ion-icon name="person-circle"></ion-icon>
                }
              </ion-avatar>
              <div class="match-item-content">
                <ion-label class="custom-label ion-no-padding ion-no-margin">
                  <div class="company-info">
                    <div class="company-name">
                      <ion-text>{{ match.organizationName }}</ion-text>
                      <ion-icon name="checkmark-circle-outline" color="rcgreen"></ion-icon>
                      <ion-text>{{ match.userFullName }}</ion-text>
                    </div>
                    <ion-text class="company-time">{{ match.foundAgo }}</ion-text>
                  </div>
                  <ion-text class="machine-type">{{ match.machineTypeNameRu }}</ion-text>
                  <p class="details">
                    <ion-text color="dark">{{ match.workDateTime }}&nbsp;</ion-text>
                    @if (match.workAmountUnit) {
                      <ion-text color="dark">{{ match.workAmountUnit }}&nbsp;</ion-text>
                    }
                    @if (match.distanceToBaseKm) {
                      <ion-text color="dark">
                        <span>&nbsp;·&nbsp;</span>
                        <ion-text color="dark">{{ match.distanceToBaseKm }}</ion-text>
                        <span>&nbsp;км от базы</span>
                      </ion-text>
                    }
                  </p>
                </ion-label>
                <ion-chip color="medium" slot="end">Завершена</ion-chip>
              </div>
            </ion-item>
          } @else {
            <ion-item class="custom-inactive-item">
              <ion-avatar class="avatar-with-icon" slot="start">
                <ion-icon name="person-circle"></ion-icon>
              </ion-avatar>
              <div>
                <ion-label class="custom-label">
                  <div class="parser-name">
                    <ion-text class="parser-name">{{ match.msgUserName }}</ion-text>
                    <ion-text>{{ match.foundAgo }}</ion-text>
                  </div>

                  <ion-text class="machine-type">{{ match.machineTypeNameRu }}</ion-text>
                  <ion-text class="parser-details">
                    {{ match.msgComment }}
                  </ion-text>
                </ion-label>
                <ion-chip color="medium" slot="end">Завершена</ion-chip>
              </div>
            </ion-item>
          }
        }
      </ion-list>
      @if (inactiveMatchesState.hasNext) {
        <ion-infinite-scroll (ionInfinite)="onInactiveMatchesInfiniteScrollInfinite($event)">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      }
    }
  </ion-content>
}
@if (currentView === "fakeOrders") {
  <ion-content>
    <ion-refresher
      [disabled]="fakeOrdersState.pending"
      (ionRefresh)="onFakeOrdersRefresh($event)"
      slot="fixed"
    >
      <ion-refresher-content
        pullingIcon="chevron-down-circle-outline"
        pullingText="Потянуть для обновления"
        refreshingSpinner=""
        refreshingText="Обновляем список..."
      ></ion-refresher-content>
    </ion-refresher>
    @if (fakeOrdersState.pending && fakeOrdersState.items.length === 0) {
      <div class="spinner-wrapper ion-padding-top">
        <ion-spinner name="lines-sharp"></ion-spinner>
      </div>
    }
    @if (fakeOrdersState.items.length) {
      <ion-list class="ion-no-padding">
        @for (fakeOrder of fakeOrdersState.items; track fakeOrder.id) {
          <ion-item
            class="custom-item"
            [routerLink]="'/entry-sign'"
            [queryParams]="fakeOrder.orderDetailsQueryParams"
          >
            <ion-avatar slot="start" class="avatar-with-icon">
              @if (fakeOrder.avatarURL) {
                <ion-img [src]="fakeOrder.avatarURL + '?w=128'"></ion-img>
              } @else {
                <ion-icon name="person-circle"></ion-icon>
              }
            </ion-avatar>
            <div class="match-item-content">
              <ion-label class="custom-label ion-no-padding ion-no-margin">
                <div class="company-info">
                  <div class="company-name">
                    <ion-text class="user-name">{{ fakeOrder.organizationName }}</ion-text>
                    @if (fakeOrder.isVerified) {
                      <ion-icon name="checkmark-circle-outline" color="rcgreen"></ion-icon>
                    }
                  </div>
                </div>
                <ion-text class="fake-order-machine-type">
                  <span>{{ fakeOrder.machineModel }}&nbsp;</span>
                  <span>(</span>
                  <span>{{ fakeOrder.machineTypeNameRu }}</span>
                  @if (fakeOrder.machineMainParam) {
                    <span>, {{ fakeOrder.machineMainParam }}</span>
                  }
                  <span>)</span>
                </ion-text>
                <p class="fake-order-details">
                  <ion-text color="dark">{{ fakeOrder.workDateTime }}&nbsp;</ion-text>
                  @if (fakeOrder.workAmountUnit) {
                    <ion-text color="dark">{{ fakeOrder.workAmountUnit }}&nbsp;</ion-text>
                  }
                </p>
                <p class="fake-order-address">
                  <ion-text>{{ fakeOrder.address }}</ion-text>
                </p>
                <p class="fake-order-price">
                  <ion-text>{{ fakeOrder.price }} {{ fakeOrder.payment }}</ion-text>
                </p>
              </ion-label>
            </div>
          </ion-item>
        }
      </ion-list>
      @if (fakeOrdersState.hasNext) {
        <ion-infinite-scroll (ionInfinite)="onFakeOrdersInfiniteScrollInfinite($event)">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      }
    }
  </ion-content>
}
