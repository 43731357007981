<ion-header>
  <ion-toolbar>
    <ion-back-button slot="start" [defaultHref]="'/home/matches'"></ion-back-button>
    <ion-title>Заявка</ion-title>
    @if (!pending && entryMatch) {
      <ion-chip slot="end" color="medium">Просмотрена</ion-chip>
    }
    <ion-progress-bar [value]="foundProgress"></ion-progress-bar>
  </ion-toolbar>
</ion-header>
@if (pending) {
  <ion-content>
    <div class="spinner-wrapper">
      <ion-spinner name="lines-sharp"></ion-spinner>
    </div>
  </ion-content>
} @else if (!pending && entryMatch) {
  <ion-content>
    <div class="ion-padding">
      <ion-text class="timestamp">Размещена {{ entryMatch.foundAgo }}</ion-text>
    </div>
    <div class="ion-padding">
      <h1 class="ion-no-margin">{{ entryMatch.machineTypeName }}</h1>
      <div class="main-message">
        <span>{{ entryMatch.message }}</span>
      </div>
    </div>
    <div class="customer-block ion-padding">
      <ion-item lines="none" color="light">
        <ion-avatar class="avatar-with-icon" slot="start">
          <ion-icon color="medium" name="person-circle"></ion-icon>
        </ion-avatar>
        <ion-text class="person-name">{{ entryMatch.fullName }}</ion-text>
      </ion-item>
      <ion-button
        (click)="onCallButtonClick($event)"
        expand="block"
        fill="outline"
        size="large"
        color="primary"
      >
        <ion-icon slot="start" name="call"></ion-icon>
        Позвонить
      </ion-button>
    </div>
    <div class="disclaimer ion-padding">
      <ion-text>
        <span>
          Данный тип заявок активен в&nbsp;течении 1&nbsp;часа, после чего они попадают
          в&nbsp;раздел &laquo;Завершенные&raquo;.
          <br />
        </span>
        <span>
          <br />
          Заявка и&nbsp;тип техники определяется автоматически. Если вы&nbsp;нашли ошибку, или
          у&nbsp;вас есть замечания к&nbsp;сервису, свяжитесь с&nbsp;нами в&nbsp;чате поддержки.
        </span>
      </ion-text>
    </div>
  </ion-content>
}
