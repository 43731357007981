import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryMatchParsedDetailsSearchParserEntryMatchByIdQueryVariables = Types.Exact<{
  entryMatchIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type EntryMatchParsedDetailsSearchParserEntryMatchByIdQuery = { __typename?: 'Query', searchParserEntryMatchById: Array<{ __typename?: 'ParserEntryMatchByIdResponse', id: string, found: any, contactName: string, contactPhone?: string | null, comment?: string | null, request: { __typename?: 'ParserEntryMatchByIdRequest', machineTypeName: string } }>, authCurrentUser?: { __typename?: 'User', uuid: string } | null };

export type EntryMatchParsedDetailsEntryMarkViewedMutationVariables = Types.Exact<{
  entryId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  uuidUser?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type EntryMatchParsedDetailsEntryMarkViewedMutation = { __typename?: 'Mutation', entryMarkViewed?: { __typename?: 'EntryMarkViewedResultType', viewed?: boolean | null } | null };

export const EntryMatchParsedDetailsSearchParserEntryMatchByIdDocument = gql`
    query EntryMatchParsedDetailsSearchParserEntryMatchById($entryMatchIds: [String!]!) {
  searchParserEntryMatchById(ids: $entryMatchIds) {
    id
    found
    request {
      machineTypeName
    }
    contactName
    contactPhone
    comment
  }
  authCurrentUser {
    uuid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryMatchParsedDetailsSearchParserEntryMatchByIdQueryGql extends Apollo.Query<EntryMatchParsedDetailsSearchParserEntryMatchByIdQuery, EntryMatchParsedDetailsSearchParserEntryMatchByIdQueryVariables> {
    override document = EntryMatchParsedDetailsSearchParserEntryMatchByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryMatchParsedDetailsEntryMarkViewedDocument = gql`
    mutation EntryMatchParsedDetailsEntryMarkViewed($entryId: String, $uuidUser: String) {
  entryMarkViewed(entryId: $entryId, uuidUser: $uuidUser) {
    viewed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryMatchParsedDetailsEntryMarkViewedMutationGql extends Apollo.Mutation<EntryMatchParsedDetailsEntryMarkViewedMutation, EntryMatchParsedDetailsEntryMarkViewedMutationVariables> {
    override document = EntryMatchParsedDetailsEntryMarkViewedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }