import { Route } from '@angular/router';
import { SupportVersionInfoComponent } from './support-version-info/support-version-info.component';
import { EntryRequestFormComponent } from './entry-request-form/entry-request-form.component';
import { EntrySignComponent } from './entry-sign/entry-sign.component';
import { ProfileMachineBaseSetComponent } from './profile-machine-base-set/profile-machine-base-set.component';
import { EntriesTabComponent } from './home-page/entries-tab/entries-tab.component';
import { MatchesTabComponent } from './home-page/matches-tab/matches-tab.component';
import { ProfileTabComponent } from './home-page/profile-tab/profile-tab.component';
import { SupportTabComponent } from './home-page/support-tab/support-tab.component';
import { EntryDetailsMachineComponent } from './entry-details-machine/entry-details-machine.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { EntryMatchParsedDetailsComponent } from './entry-match-parsed-details/entry-match-parsed-details.component';

export const appRoutes: Route[] = [
  {
    path: 'home',
    loadComponent: () => import('./home-page/home-page.component').then((m) => m.HomePageComponent),
    children: [
      {
        path: 'entries',
        component: EntriesTabComponent,
      },
      {
        path: 'matches',
        component: MatchesTabComponent,
      },
      {
        path: 'profile',
        component: ProfileTabComponent,
      },
      {
        path: 'support',
        component: SupportTabComponent,
      },
    ],
  },
  {
    path: 'sign-in',
    loadComponent: () =>
      import('./sign-in-with-phone-number/sign-in-with-phone-number.component').then(
        (mod) => mod.SignInWithPhoneNumberComponent,
      ),
  },
  {
    path: 'registration',
    loadComponent: () =>
      import('./registration-page/registration-page.component').then(
        (mod) => mod.RegistrationPageComponent,
      ),
  },
  {
    path: 'registration-unavailable-region',
    loadComponent: () =>
      import('./registration-unavailable-region/registration-unavailable-region.component').then(
        (mod) => mod.RegistrationUnavailableRegionComponent,
      ),
  },
  {
    path: 'registration-profile',
    loadComponent: () =>
      import('./registration-profile/registration-profile.component').then(
        (mod) => mod.RegistrationProfileComponent,
      ),
  },
  {
    path: 'registration-company',
    loadComponent: () =>
      import('./registration-company/registration-company.component').then(
        (mod) => mod.RegistrationCompanyComponent,
      ),
  },
  {
    path: 'profile-person-edit',
    loadComponent: () =>
      import('./profile-person-edit/profile-person-edit.component').then(
        (mod) => mod.ProfilePersonEditComponent,
      ),
  },
  {
    path: 'profile-machine-add-edit',
    loadComponent: () =>
      import('./profile-machine-add-edit/profile-machine-add-edit.component').then(
        (mod) => mod.ProfileMachineAddEditComponent,
      ),
  },
  {
    path: 'profile-machine-viewing',
    loadComponent: () =>
      import('./profile-machine-viewing/profile-machine-viewing.component').then(
        (mod) => mod.ProfileMachineViewingComponent,
      ),
  },
  {
    path: 'catalog-machine-viewing',
    loadComponent: () =>
      import('./catalog-machine-viewing/catalog-machine-viewing.component').then(
        (mod) => mod.CatalogMachineViewingComponent,
      ),
  },
  {
    path: 'profile-machine-ownership',
    loadComponent: () =>
      import('./profile-machine-ownership/profile-machine-ownership.component').then(
        (mod) => mod.ProfileMachineOwnershipComponent,
      ),
  },
  {
    path: 'profile-notification-settings',
    loadComponent: () =>
      import('./profile-notification-settings/profile-notification-settings.component').then(
        (mod) => mod.ProfileNotificationSettingsComponent,
      ),
  },
  {
    path: 'profile-machine-base-set',
    component: ProfileMachineBaseSetComponent,
  },
  {
    path: 'entry-details-machine',
    component: EntryDetailsMachineComponent,
  },
  {
    path: 'entry-request-form',
    component: EntryRequestFormComponent,
  },
  {
    path: 'entry-sign',
    component: EntrySignComponent,
  },
  {
    path: 'support-version-info',
    component: SupportVersionInfoComponent,
  },
  {
    path: 'welcome',
    component: WelcomePageComponent,
  },
  {
    path: 'entry-match-parsed-details',
    component: EntryMatchParsedDetailsComponent,
  },
];
